//WorkSection.jsx




import React from 'react';
import { Container, Grid, Box, Typography, Link, IconButton, Paper, Divider } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import logo from '../images/logo.png'; // Adjust the path to your logo image

const WorkSection = () => (
  <Box
    id="work"
    sx={{
      position: 'absolute',
      zIndex: 3,
      width: '100%',
      height: '100%',
      backgroundColor: '#f9f9f9',
      overflowY: 'scroll',
      overflowX: 'hidden',
      top: 0,
      pointerEvents: 'all',
      paddingTop: '5rem',
    }}
  >
    <Container>
      <Grid container spacing={5} justifyContent="center">
        <Grid item xs={12} md={8} lg={6}>
          <Box mb={5} textAlign="center">
            <Typography variant="h4" gutterBottom color="primary">
              Our Research and Innovation
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Discover our pioneering AI research and innovative solutions designed to tackle complex industry challenges and drive operational excellence.
            </Typography>
          </Box>
        </Grid>

        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Paper
              component="a"
              href="/sherlock.pdf"
              sx={{
                display: 'block',
                position: 'relative',
                textDecoration: 'none',
                boxShadow: 3,
                '&:hover': {
                  boxShadow: 6,
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: 'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
                  opacity: 0,
                  transition: 'opacity .3s ease-in-out',
                  display: 'flex',
                  alignItems: 'flex-end',
                  p: 2,
                  color: '#fff',
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <Typography variant="body2">
                  Fraud Detection<br />
                  <span style={{ fontSize: '0.85rem' }}>Case Study</span>
                </Typography>
              </Box>
              <Box component="img" src="/images/sherlock.png" alt="Fraud Detection Case Study" sx={{ width: '100%', height: 'auto', borderRadius: 1 }} />
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              component="a"
              href="/icp.pdf"
              sx={{
                display: 'block',
                position: 'relative',
                textDecoration: 'none',
                boxShadow: 3,
                '&:hover': {
                  boxShadow: 6,
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: 'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
                  opacity: 0,
                  transition: 'opacity .3s ease-in-out',
                  display: 'flex',
                  alignItems: 'flex-end',
                  p: 2,
                  color: '#fff',
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <Typography variant="body2">
                  Internet Computer Protocol<br />
                  <span style={{ fontSize: '0.85rem' }}>Research Paper</span>
                </Typography>
              </Box>
              <Box component="img" src="/images/icp.webp" alt="Internet Computer Protocol Research Paper" sx={{ width: '100%', height: 'auto', borderRadius: 1 }} />
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              component="a"
              href="/hive.pdf"
              sx={{
                display: 'block',
                position: 'relative',
                textDecoration: 'none',
                boxShadow: 3,
                '&:hover': {
                  boxShadow: 6,
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: 'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
                  opacity: 0,
                  transition: 'opacity .3s ease-in-out',
                  display: 'flex',
                  alignItems: 'flex-end',
                  p: 2,
                  color: '#fff',
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <Typography variant="body2">
                  Smart Business Solutions<br />
                  <span style={{ fontSize: '0.85rem' }}>Innovation Spotlight</span>
                </Typography>
              </Box>
              <Box component="img" src="/images/hai.png" alt="Smart Business Solutions" sx={{ width: '100%', height: 'auto', borderRadius: 1 }} />
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={12} md={8} lg={6}>
          <Box mt={5} mb={5} textAlign="center">
            <Typography variant="h5" gutterBottom color="primary">
              Meet Our Experts
            </Typography>
            <Typography variant="body1">
              Our team of AI specialists and researchers is dedicated to pushing the boundaries of technology.{' '}
              <Link href="#!team" underline="hover" color="primary">Learn more about our team</Link>.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={8} lg={6}>
          <Box mb={9} textAlign="center">
            <Typography variant="h5" gutterBottom color="primary">
              Interested in Collaboration?
            </Typography>
            <Typography variant="body1">
              We'd love to hear from you! Reach out to us at{' '}
              <Link href="mailto:info@hiveai.tech" underline="hover" color="primary">info@hiveai.tech</Link>.
            </Typography>
          </Box>
        </Grid>

        <Divider sx={{ mt: 5, mb: 5 }} />

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} textAlign="center">
            <Box component="img" src={logo} alt="Hive AI Logo" width={85} />
            <Typography variant="caption" display="block" sx={{ mt: 2 }} color="text.secondary">
              Hive AI Research
            </Typography>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <IconButton component="a" href="https://twitter.com/hiveforensics" target="_blank" color="primary">
              <TwitterIcon fontSize="large" />
            </IconButton>
            <IconButton component="a" href="https://www.linkedin.com/company/hive-ai-tech" target="_blank" color="primary">
              <LinkedInIcon fontSize="large" />
            </IconButton>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Link href="/" underline="hover" color="primary">
              Close
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default WorkSection;






// import React from 'react';

// const WorkSection = () => (
//   <section id="work" className="work idle">
//     <div className="work__content">
//       <div className="container">
//         <div className="row initial-position">
//           <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 work__content__box first">
//             <h5>Our Research and Innovation</h5>
//             <p>Discover our pioneering AI research and innovative solutions designed to tackle complex industry challenges and drive operational excellence.</p>
//           </div>
//         </div>

//         <div className="row work__content__thumbnails">
//           <div className="col-12 col-md-4 project show">
//             <a href="/sherlock.pdf" className="thumbnail">
//               <div className="overlay">
//                 <div className="project-info">
//                   <p>Fraud Detection<br /><span>Case Study</span></p>
//                 </div>
//               </div>
//               <img src="/images/sherlock.png" alt="Fraud Detection Case Study" />
//             </a>
//           </div>
//           {/* Add other projects similarly */}
//         </div>

//         <div className="row">
//           <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 work__content__box">
//             <h5>Meet Our Experts</h5>
//             <p>Our team of AI specialists and researchers is dedicated to pushing the boundaries of technology. <a className="underline" href="#!team">Learn more about our team</a>.</p>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 work__content__box last">
//             <h5>Interested in Collaboration?</h5>
//             <p>We'd love to hear from you! Reach out to us at <a className="underline" href="mailto:info@hiveai.tech">info@hiveai.tech</a></p>
//           </div>
//         </div>
//       </div>
//       <img className="logo" src="/images/logo.png" width="85" alt="Hive AI Logo" />
//       <div className="meta name">
//         <p><small>Hive AI Research</small></p>
//       </div>
//       <div className="meta social">
//         <ul>
//           <li><a href="https://twitter.com/hiveforensics" target="_blank" rel="noopener noreferrer"><span data-hover="Twitter">Twitter</span></a></li>
//           <li><a href="https://www.linkedin.com/company/hiveforensics" target="_blank" rel="noopener noreferrer"><span data-hover="LinkedIn">LinkedIn</span></a></li>
//         </ul>
//       </div>
//       <div className="meta sound">
//         <a className="mute-action" href="#"></a>
//       </div>
//       <a href="#" className="close-button bottom">Close</a>
//     </div>
//   </section>
// );

// export default WorkSection;
