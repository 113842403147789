// AboutSection.jsx

import React from 'react';
import { Container, Grid, Box, Typography, List, ListItem, IconButton, Button } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const AboutSection = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/'); // Navigate back to the home page
  };

  return (
    <Box
      id="about"
      sx={{
        position: 'absolute',
        zIndex: 2,
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        overflowY: 'scroll',
        top: 0,
        pointerEvents: 'all',
        paddingTop: '64px', // Padding for fixed close button
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'fixed',
          top: '16px',
          right: '16px',
          zIndex: 3,
          backgroundColor: 'rgba(0,0,0,0.5)',
          color: '#fff',
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.7)',
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      
      <Container sx={{ py: 5 }}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} md={8} lg={6}>
            <Box mb={5}>
              <Typography variant="h4" gutterBottom>
                About Hive AI - Advancing the Frontiers of AI Research
              </Typography>
              <Typography variant="body1" paragraph>
                Our strategy revolves around harnessing cutting-edge machine learning techniques to uncover insights
                that push the boundaries of human intuition. By doing so, we can delve into uncharted territories of AI
                research, expand the repertoire of available resources, and unlock unprecedented performance capabilities.
              </Typography>
              <List sx={{ display: 'flex', mt: 2 }}>
                <ListItem disableGutters>
                  <IconButton component="a" href="https://twitter.com/hiveforensics" target="_blank" aria-label="Twitter">
                    <TwitterIcon fontSize="large" />
                  </IconButton>
                </ListItem>
                <ListItem disableGutters>
                  <IconButton component="a" href="https://facebook.com/hiveforensics" target="_blank" aria-label="Facebook">
                    <FacebookIcon fontSize="large" />
                  </IconButton>
                </ListItem>
                <ListItem disableGutters>
                  <IconButton component="a" href="https://linkedin.com/company/hive-ai" target="_blank" aria-label="LinkedIn">
                    <LinkedInIcon fontSize="large" />
                  </IconButton>
                </ListItem>
              </List>
            </Box>
          </Grid>

          <Grid item xs={12} md={8} lg={6}>
            <Box mb={5}>
              <Typography variant="h4" gutterBottom>
                Our Mission
              </Typography>
              <Typography variant="body1" paragraph>
                Our relentless pursuit of excellence leads us to develop breakthrough AI systems that learn, adapt,
                and solve complex problems. By pushing the boundaries of knowledge, we open doors to limitless possibilities
                and drive scientific discoveries.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: '#E0FFFD',
                p: 5,
                mt: 5,
                borderRadius: 2,
              }}
            >
              <Grid container justifyContent="center">
                <Grid item xs={12} md={8} lg={6}>
                  <Typography variant="h4" gutterBottom>
                    Enhancing Security and Efficiency
                  </Typography>
                  <Typography variant="body1" paragraph>
                    By harnessing the potential of artificial intelligence, we transform the way security and efficiency are achieved. Our AI
                    solutions analyze vast amounts of data, leading to faster response times, fortified security operations, and optimized processes.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={8} lg={6}>
            <Box mb={5}>
              <Typography variant="h4" gutterBottom>
                Data-Driven Innovation
              </Typography>
              <Typography variant="body1" paragraph>
                We are at the forefront of revolutionizing the AI landscape by harnessing the power of data and artificial intelligence. Through our
                innovative approach, we drive transformative advancements in various domains, shaping the future of AI.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={8} lg={6}>
            <Box mb={9}>
              <Typography variant="h4" gutterBottom>
                Embracing the Future with AI-driven Innovation
              </Typography>
              <Typography variant="body1" paragraph>
                With our unwavering commitment to research and development, we embrace the future by continuously innovating and delivering AI-driven
                solutions that redefine industries, improve lives, and shape a better tomorrow.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutSection;


