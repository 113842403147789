import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MetaTags from './components/MetaTags';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import WorkSection from './components/WorkSection';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <MetaTags />
        <Routes>
          <Route path="/" element={<HeroSection />} />
          <Route path="/about" element={<AboutSection />} />
          <Route path="/work" element={<WorkSection />} />
          {/* Add more routes here if needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;




// import React from 'react';
// import MetaTags from './components/MetaTags';
// import HeroSection from './components/HeroSection';
// import AboutSection from './components/ AboutSection';
// import WorkSection from './components/WorkSection';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <MetaTags />
//       <HeroSection />
//       <AboutSection />
//       <WorkSection />
//     </div>
//   );
// }

// export default App;
