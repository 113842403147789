// AboutSection.jsx


import React, { useEffect } from 'react';
import * as THREE from 'three';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const HeroSection = () => {
  const navigate = useNavigate();  // Replacing useHistory with useNavigate

  useEffect(() => {
    let camera, scene, renderer;
    const particles = [];
    let count = 0;
    const SEPARATION = 100, AMOUNTX = 50, AMOUNTY = 50;
    let mouseX = 0, mouseY = 0;
    let windowHalfX = window.innerWidth / 2;
    let windowHalfY = window.innerHeight / 2;

    function init() {
      const hero = document.getElementById("hero");
      const container = document.createElement("div");
      container.className = "hero__three-container";
      hero.appendChild(container);

      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
      camera.position.z = 1000;

      scene = new THREE.Scene();

      // Particle Grid Background
      const particleMaterial = new THREE.PointsMaterial({
        color: 0xFF6AFF,
        size: 3,
      });

      const particleGeometry = new THREE.BufferGeometry();
      const positions = new Float32Array(AMOUNTX * AMOUNTY * 3);

      let i = 0;
      for (let ix = 0; ix < AMOUNTX; ix++) {
        for (let iy = 0; iy < AMOUNTY; iy++) {
          positions[i] = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2;
          positions[i + 1] = 0;
          positions[i + 2] = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2;
          i += 3;
        }
      }

      particleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));

      const points = new THREE.Points(particleGeometry, particleMaterial);
      scene.add(points);

      const spriteMaterial = new THREE.SpriteMaterial({
        color: 0xFF6AFF,
        map: new THREE.TextureLoader().load('path/to/circle.png'),
        blending: THREE.AdditiveBlending,
      });

      for (let i = 0; i < 1000; i++) {
        const particle = new THREE.Sprite(spriteMaterial);
        particle.position.x = Math.random() * 2 - 1;
        particle.position.y = Math.random() * 2 - 1;
        particle.position.z = Math.random() * 2 - 1;
        particle.position.normalize();
        particle.position.multiplyScalar(Math.random() * 10 + 450);
        particle.scale.multiplyScalar(2);
        scene.add(particle);
      }

      for (let i = 0; i < 300; i++) {
        const geometry = new THREE.BufferGeometry();
        const vertices = new Float32Array(6);

        const vertex = new THREE.Vector3(Math.random() * 2 - 1, Math.random() * 2 - 1, Math.random() * 2 - 1);
        vertex.normalize();
        vertex.multiplyScalar(450);

        vertices[0] = vertex.x;
        vertices[1] = vertex.y;
        vertices[2] = vertex.z;

        const vertex2 = vertex.clone();
        vertex2.multiplyScalar(Math.random() * 0.3 + 1);

        vertices[3] = vertex2.x;
        vertices[4] = vertex2.y;
        vertices[5] = vertex2.z;

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

        const lineMaterial = new THREE.LineBasicMaterial({
          color: 0xFF6AFF,
          opacity: Math.random(),
          transparent: true,
        });

        const line = new THREE.Line(geometry, lineMaterial);
        scene.add(line);
      }

      renderer = new THREE.WebGLRenderer({ alpha: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(renderer.domElement);

      document.addEventListener('mousemove', onDocumentMouseMove, false);
      window.addEventListener('resize', onWindowResize, false);

      animate(points, particleGeometry);
    }

    function onWindowResize() {
      windowHalfX = window.innerWidth / 2;
      windowHalfY = window.innerHeight / 2;

      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    function onDocumentMouseMove(event) {
      mouseX = event.clientX - windowHalfX;
      mouseY = event.clientY - windowHalfY;
    }

    function animate(points, geometry) {
      requestAnimationFrame(() => animate(points, geometry));
      render(points, geometry);
    }

    function render(points, geometry) {
      camera.position.x += (mouseX - camera.position.x) * 0.05;
      camera.position.y += (-mouseY + 200 - camera.position.y) * 0.05;
      camera.lookAt(scene.position);

      const positions = geometry.attributes.position.array;
      let i = 0;

      for (let ix = 0; ix < AMOUNTX; ix++) {
        for (let iy = 0; iy < AMOUNTY; iy++) {
          positions[i + 1] = Math.sin((ix + count) * 0.3) * 50 +
            Math.sin((iy + count) * 0.5) * 50;
          i += 3;
        }
      }

      geometry.attributes.position.needsUpdate = true;

      renderer.setClearColor(0x000000, 0);
      renderer.render(scene, camera);

      count += 0.1;
    }

    init();

    return () => {
      const container = document.querySelector('.hero__three-container');
      if (container) {
        container.remove();
      }
      document.removeEventListener('mousemove', onDocumentMouseMove, false);
      window.removeEventListener('resize', onWindowResize, false);
    };
  }, []);

  const handleClick = () => {
    navigate('/about'); // Navigate to the About page
  };

  const handleWorkClick = () => {
    navigate('/work'); // Navigate to the Portfolio page
  };

  return (
    <section id="hero" className="hero dark sphere">
      <div className="hero__content">
        <p>Where Technology Meets</p>
        <h1>Imagination</h1>
      </div>

      <button onClick={handleClick} className="hero__trigger-button top">About</button>
      <button onClick={handleWorkClick} className="hero__trigger-button bottom">Portfolio</button>

      <img className="logo" src="images/logo_old.png" width="70" height="45" alt="Hive AI Logo" />

      <div className="meta sound">
        <a className="mute-action muted" href="#"></a>
      </div>
    </section>
  );
};

export default HeroSection;









// import React, { useEffect } from 'react';
// import * as THREE from 'three';
// import '../App.css'; // Adjusted CSS import path
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

// const HeroSection = () => {
//   useEffect(() => {
//     let camera, scene, renderer;
//     let particles = [], count = 0;
//     const SEPARATION = 100, AMOUNTX = 50, AMOUNTY = 50;
//     let mouseX = 0, mouseY = 0;
//     let windowHalfX = window.innerWidth / 2;
//     let windowHalfY = window.innerHeight / 2;

//     function init() {
//       const hero = document.getElementById("hero");
//       const container = document.createElement("div");
//       container.className = "hero__three-container";
//       hero.appendChild(container);

//       camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
//       camera.position.z = 1000;

//       scene = new THREE.Scene();

//       // Particle Grid Background
//       const particleMaterial = new THREE.PointsMaterial({
//         color: 0xFF6AFF, // Updated to match the sphere color
//         size: 3,
//       });

//       let particleGeometry = new THREE.BufferGeometry();
//       let positions = [];

//       for (let ix = 0; ix < AMOUNTX; ix++) {
//         for (let iy = 0; iy < AMOUNTY; iy++) {
//           positions.push(
//             ix * SEPARATION - (AMOUNTX * SEPARATION) / 2,
//             0,
//             iy * SEPARATION - (AMOUNTY * SEPARATION) / 2
//           );
//         }
//       }

//       particleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));

//       const points = new THREE.Points(particleGeometry, particleMaterial);
//       scene.add(points);

//       // Sphere and Particles
//       const spriteMaterial = new THREE.SpriteMaterial({
//         color: 0xFF6AFF,
//         map: new THREE.TextureLoader().load('path/to/circle.png'),
//         blending: THREE.AdditiveBlending
//       });

//       for (let i = 0; i < 1000; i++) {
//         const particle = new THREE.Sprite(spriteMaterial);
//         particle.position.x = Math.random() * 2 - 1;
//         particle.position.y = Math.random() * 2 - 1;
//         particle.position.z = Math.random() * 2 - 1;
//         particle.position.normalize();
//         particle.position.multiplyScalar(Math.random() * 10 + 450);
//         particle.scale.multiplyScalar(2);
//         scene.add(particle);
//       }

//       // Lines
//       for (let i = 0; i < 300; i++) {
//         const geometry = new THREE.BufferGeometry();
//         const vertices = [];
//         const vertex = new THREE.Vector3(Math.random() * 2 - 1, Math.random() * 2 - 1, Math.random() * 2 - 1);
//         vertex.normalize();
//         vertex.multiplyScalar(450);

//         vertices.push(vertex.x, vertex.y, vertex.z);

//         const vertex2 = vertex.clone();
//         vertex2.multiplyScalar(Math.random() * 0.3 + 1);

//         vertices.push(vertex2.x, vertex2.y, vertex2.z);

//         geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

//         const lineMaterial = new THREE.LineBasicMaterial({
//           color: 0xFF6AFF,
//           opacity: Math.random(),
//           transparent: true
//         });

//         const line = new THREE.Line(geometry, lineMaterial);
//         scene.add(line);
//       }

//       renderer = new THREE.WebGLRenderer({ alpha: true });
//       renderer.setPixelRatio(window.devicePixelRatio);
//       renderer.setSize(window.innerWidth, window.innerHeight);
//       container.appendChild(renderer.domElement);

//       document.addEventListener('mousemove', onDocumentMouseMove, false);
//       window.addEventListener('resize', onWindowResize, false);

//       animate(points, particleGeometry);
//     }

//     function onWindowResize() {
//       windowHalfX = window.innerWidth / 2;
//       windowHalfY = window.innerHeight / 2;

//       camera.aspect = window.innerWidth / window.innerHeight;
//       camera.updateProjectionMatrix();

//       renderer.setSize(window.innerWidth, window.innerHeight);
//     }

//     function onDocumentMouseMove(event) {
//       mouseX = event.clientX - windowHalfX;
//       mouseY = event.clientY - windowHalfY;
//     }

//     function animate(points, geometry) {
//       requestAnimationFrame(() => animate(points, geometry));
//       render(points, geometry);
//     }

//     function render(points, geometry) {
//       camera.position.x += (mouseX - camera.position.x) * 0.05;
//       camera.position.y += (-mouseY + 200 - camera.position.y) * 0.05;
//       camera.lookAt(scene.position);

//       // Update positions for particle grid background
//       let positions = geometry.attributes.position.array;
//       let i = 0;

//       for (let ix = 0; ix < AMOUNTX; ix++) {
//         for (let iy = 0; iy < AMOUNTY; iy++) {
//           positions[i + 1] = Math.sin((ix + count) * 0.3) * 50 +
//             Math.sin((iy + count) * 0.5) * 50;
//           i += 3;
//         }
//       }

//       geometry.attributes.position.needsUpdate = true;

//       renderer.setClearColor(0x000000, 0);
//       renderer.render(scene, camera);

//       count += 0.1;
//     }

//     init();

//     return () => {
//       const container = document.querySelector('.hero__three-container');
//       if (container) {
//         container.remove(); // Remove the container element directly
//       }
//       document.removeEventListener('mousemove', onDocumentMouseMove, false);
//       window.removeEventListener('resize', onWindowResize, false);
//     };
//   }, []);

//   return (
//     <section id="hero" className="hero dark sphere">
//       <div className="hero__content">
//         <p>Where Technology Meets</p>
//         <h1>Imagination</h1>
//       </div>

//       <a href="#about" className="hero__trigger-button top">About</a>
//       <a href="#portfolio" className="hero__trigger-button bottom">Portfolio</a>

//       <img className="logo" src="images/logo_old.png" width="70" height="45" alt="Hive AI Logo" />

//       <div className="meta social">
//         <ul>
//           <li>
//             <a href="https://twitter.com/hiveforensics" target="_blank" rel="noopener noreferrer">
//               <FontAwesomeIcon icon={faTwitter} size="lg" className="social-icon" />
//             </a>
//           </li>
//           <li>
//             <a href="https://www.linkedin.com/company/hive-ai" target="_blank" rel="noopener noreferrer">
//               <FontAwesomeIcon icon={faLinkedin} size="lg" className="social-icon" />
//             </a>
//           </li>
//         </ul>
//       </div>

//       <div className="meta sound">
//         <a className="mute-action muted" href="#"></a>
//       </div>
//     </section>
//   );
// };

// export default HeroSection;
